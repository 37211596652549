import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ManualInvoiceLine } from 'app/models/ReportRequest';
import { CompanyInvoice, CompanyInvoiceHistoryItem, CompanyInvoicePagedRequest } from 'app/models/company-invoice';
import { PagedResponse } from 'app/models/pagination';
import { GetInvoiceHistoryAction, ResetInvoiceHistoryAction } from 'app/redux/actions/invoice';
import { State, getGSettingsGlobalSettingsModel, getInvoiceHistory, getInvoiceLoading } from 'app/redux/reducers';
import { SuccessStatus } from 'app/shared/enums';
import { UserSettings } from 'app/models/user-settings';

@Component({
  selector: 'app-invoice-information',
  templateUrl: './invoice-information.component.html',
  styleUrls: ['./invoice-information.component.scss']
})
export class InvoiceInformationComponent implements OnInit, OnChanges {
  @Input() public invoice: CompanyInvoice;
  @Input() public isMobile: boolean;
  @Input() public filterOptions: CompanyInvoicePagedRequest = new CompanyInvoicePagedRequest();
  @Output() public closeDrawer: EventEmitter<void> = new EventEmitter<void>();

  public history$: Observable<PagedResponse<CompanyInvoiceHistoryItem>>;
  public invoiceLoading$: Observable<boolean>;
  public getGlobalSettingsModel$: Observable<UserSettings>;
  public selectedTabIndex: number = 0;
  public selectedTabLabel: 'Details' | 'Line Items' | 'History' = 'Details';
  public editInvoiceForm: UntypedFormGroup = new UntypedFormGroup({
    invoiceId: new UntypedFormControl(),
    companyId: new UntypedFormControl(),
    clientId: new UntypedFormControl(),
    clientName: new UntypedFormControl(),
    externalId: new UntypedFormControl(),
    sagaId: new UntypedFormControl(),
    userId: new UntypedFormControl(),
    amount: new UntypedFormControl(),
    formattedAmount: new UntypedFormControl(),
    hours: new UntypedFormControl(),
    createdAt: new UntypedFormControl(),
    updatedAt: new UntypedFormControl(),
    destination: new UntypedFormControl(),
    status: new UntypedFormControl(),
    formattedStatus: new UntypedFormControl(),
    provider: new UntypedFormControl(),
    dueAt: new UntypedFormControl(),
    invoiceUsername: new UntypedFormControl(),
    invoiceStartDate: new UntypedFormControl(),
    invoiceEndDate: new UntypedFormControl(),
    invoicePrefix: new UntypedFormControl(),
    projectId: new UntypedFormControl(),
    lineItems: new UntypedFormArray([]),
    formattedInvoiceNumber: new UntypedFormControl()
  });

  constructor(
    private store: Store<State>
  ){
  }

  public ngOnInit(): void {
    this.history$ = this.store.select(getInvoiceHistory);
    this.getGlobalSettingsModel$ = this.store.select(getGSettingsGlobalSettingsModel);
    this.invoiceLoading$ = this.store.select(getInvoiceLoading)
      .pipe(map(s => s === SuccessStatus.IsLoading));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.invoice) {
      this.selectedTabLabel = 'Details';
      this.selectedTabIndex = 0;
      this.store.dispatch(ResetInvoiceHistoryAction());
    }
    if (changes.invoice?.currentValue) {
      this.editInvoiceForm.reset();
      this.editInvoiceForm.patchValue({
        ...changes.invoice.currentValue,
        destination: changes.invoice.currentValue.destination?.split(',') ?? [],
        dueAt: new Date(changes.invoice.currentValue.dueAt),
        createdAt: new Date(changes.invoice.currentValue.createdAt)
      });
      this.getFirstPageForInvoiceHistory();
      this.updateLineItems();
      this.editInvoiceForm.disable();
    }
  }

  public selectTab(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
    this.selectedTabLabel = event.tab.textLabel as 'Details' | 'Line Items' | 'History';
  }

  private updateLineItems(): void {
    this.lineItems.clear();
    this.invoice.lineItems.forEach((item: ManualInvoiceLine) => {
      this.lineItems.push(
        new UntypedFormGroup({
          primaryValue: new UntypedFormControl(item.primaryValue),
          secondaryValue: new UntypedFormControl(item.secondaryValue),
          billedRate: new UntypedFormControl(item.billedRate),
          amount: new UntypedFormControl(item.amount),
          quantity: new UntypedFormControl(item.quantity),
          createdAt: new UntypedFormControl(item.createdAt)
        })
      );
    });
  }

  private get lineItems(): UntypedFormArray {
    return this.editInvoiceForm.controls.lineItems as UntypedFormArray;
  }

  private getFirstPageForInvoiceHistory(): void {
    this.store.dispatch(GetInvoiceHistoryAction({
      payload: {
        limit: 8,
        page: 1,
        invoiceId: this.invoice.invoiceId
      }
    }));
  }
}
